import { render, staticRenderFns } from "./chat-list-item.vue?vue&type=template&id=91d812aa"
import script from "./chat-list-item.vue?vue&type=script&lang=js"
export * from "./chat-list-item.vue?vue&type=script&lang=js"
import style0 from "./chat-list-item.vue?vue&type=style&index=0&id=91d812aa&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports