<template>
  <div class="reply-msg">
    <div class="r-msg-stick" />
    <div class="r-msg">
      <div class="r-msg-fio">{{ fullname }}</div>
      <div class="r-msg-text" v-if="isLink">{{ replyMsg.text }}</div>
      <div class="r-msg-text" v-else v-html="searchAddLink(replyMsg.text)" />
    </div>
  </div>
</template>

<script>
import { searchAddLink } from '@/comp/chat/func'
export default {
  name: 'reply-msg',
  components: {},
  props: {
    replyMsg: { type: Object }
  },
  data() {
    return {
      fullname: ''
    }
  },
  computed: {
    isLink() {
      return this.replyMsg.text.indexOf('http') === -1
    }
  },
  methods: {
    searchAddLink(text) {
      return searchAddLink(text)
    }
  },
  created() {
    console.log(this.replyMsg)
    this.fullname = this.replyMsg.is_anon ? 'Анонимный пользователь' : this.replyMsg.fullname
  }
}
</script>

<style lang="sass">
@import './styles/index.scss'
</style>
