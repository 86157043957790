export const searchAddLink = (msg) => {
  let startIndex = msg.indexOf('http')

  while (startIndex !== -1) {
    let endIndex = 0
    endIndex = msg.indexOf(' ', startIndex)
    if (endIndex === -1) {
      endIndex = msg.length
    }

    const url = msg.substring(startIndex, endIndex)
    const urlLen = url.length
    const URL = `<a href='${url}' target='_blank'>${url}</a>`
    const URLLen = URL.length
    const d = URLLen - urlLen
    msg = msg.replace(url, URL)
    startIndex = msg.indexOf('http', endIndex + d)
  }
  return msg
}
