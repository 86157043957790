<template>
  <ChatOnline
    :chat-list="chatList"
    :username="username"
    :is-anon-editor="isAnonEditor"
    :is-admin="isAdminOwn"
    :is-past="true"
    :replyMsg="msg"
    @on-send-msg="onSendMsg"
    @on-is-anon="onIsAnon"
  />
</template>

<script>
import ChatOnline from './chat-online'
import { mapGetters, mapMutations } from 'vuex'
import { GETTER_APP, MUTATION_APP } from '@/store/const/type'
import {
  ACTION_CHAT,
  delAxiosMsg,
  getAxiosMessage,
  getCentrifuge,
  getHistoryMsg,
  patchAxiosReactionMsg,
  postAxiosMessage,
  postAxiosReactionMsg
} from '../func'
import { pathAxiosShowMsg } from '@/comp/chat/func/pathAxiosShowMsg'

export default {
  name: 'index-chat-online',
  components: { ChatOnline },
  props: {
    isAnonEditor: { type: Boolean },
    owner: { type: String }
  },
  data() {
    return {
      channel: this.$route.params.id,
      chatList: {},
      centrifuge: null,
      idInterval: 0,
      idMsg: ''
    }
  },

  methods: {
    ...mapMutations({ setMutationIsAppAnon: MUTATION_APP.IS_ANON }),
    onSendMsg({ text, is_question, is_anon }) {
      const { fullname, username, avatar } = this.user
      if (!text || !fullname || !username) return
      const time = new Date().toISOString()
      const msg = {
        fullname,
        username,
        text,
        time,
        avatar,
        is_question,
        is_anon,
        id_reply: this.idMsg ? this.idMsg : null
      }

      postAxiosMessage(this.channel, msg)
      this.idMsg = ''
    },
    onIsAnon(isAnon) {
      this.setMutationIsAppAnon(isAnon)
    },
    clearChatList() {
      this.chatList = {}
    },
    updateChatReaction({ id, reactions }) {
      if (!id) return
      this.chatList[id].reactions = reactions
    },
    showChatMsg({ id }, hide) {
      this.chatList[id].deleted = hide
    }
  },

  computed: {
    ...mapGetters({ getGettersUser: GETTER_APP.USER, isAdmin: GETTER_APP.IS_ADMIN }),
    username() {
      return this.getGettersUser.username
    },
    isAdminOwn() {
      if (this.isAdmin) return true
      return this.owner === this.username
    },
    msg() {
      // console.log()
      if (this.idMsg.length > 0) return this.chatList[this.idMsg]
      return null
    }
  },
  mounted() {
    this.$root.$on('on-root-reaction-msg', (type, id, isSelf) => {
      if (isSelf) postAxiosReactionMsg(this.channel, type, id, this.username)
      else patchAxiosReactionMsg(this.channel, id, this.username)
    })
    this.$root.$on('on-root-del-msg', (id, isHide) => {
      if (isHide) delAxiosMsg(this.channel, id)
      else pathAxiosShowMsg(this.channel, id)
    })
    this.$root.$on('on-root-reply-msg', (id) => {
      this.idMsg = id
    })
  },

  async created() {
    const { username, fullname, avatar, accessToken } = this.$store.state.app.user
    this.user = { username, fullname, avatar }

    // get messages History
    const messages = await getAxiosMessage(this.channel)
    this.chatList = getHistoryMsg(messages)

    // connect centrifuge
    const centrifuge = getCentrifuge(accessToken)

    // subscribe to incoming messages
    const subscription = centrifuge.subscribe(this.channel, (ctx) => {
      const { id, ...props } = ctx.data
      if (id) {
        this.chatList = { ...this.chatList, [id]: { id, ...props } }
      } else {
        const { type, payload } = props
        if (!type) return
        if (ACTION_CHAT.CLEAR === type) this.clearChatList()
        if (ACTION_CHAT.REACTIONS === type) this.updateChatReaction(payload)
        if (ACTION_CHAT.HIDE_MSG_BY_ID === type) this.showChatMsg(payload, true)
        if (ACTION_CHAT.SHOW_MSG_BY_ID === type) this.showChatMsg(payload, false)
      }
    })

    // online users
    subscription.presenceStats().then(
      ({ num_users }) => this.$emit('on-online-users', num_users),
      (err) => console.log(err)
    )
    //TODO Duplicate 30s online users
    this.idInterval = setInterval(() => {
      subscription.presenceStats().then(
        ({ num_users }) => this.$emit('on-online-users', num_users),
        (err) => console.log(err)
      )
    }, 30000)
    this.centrifuge = centrifuge
  },

  destroyed() {
    clearInterval(this.idInterval)
    this.centrifuge.disconnect()
    this.setMutationIsAppAnon(false)
    this.$root.$off('on-root-reaction-msg')
    this.$root.$off('on-root-del-msg')
    this.$root.$off('on-root-reply-msg')
  }
}
</script>

<style lang="scss">
@import '../styles';
</style>
