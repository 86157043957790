export const CHAT = {
  ALL: 0,
  QUESTION: 1
}

export const ACTION_CHAT = {
  CLEAR: 'ACTION_CHAT_CLEAR',
  REACTIONS: 'ACTION_CHAT_REACTIONS',
  HIDE_MSG_BY_ID: 'ACTION_CHAT_HIDE_MSG_BY_ID',
  SHOW_MSG_BY_ID: 'ACTION_CHAT_SHOW_MSG_BY_ID'
}

export const REACTIONS = {
  LIKE: 'like',
  DISLIKE: 'dislike',
  FIRE: 'fire',
  FAVORITE: 'favorite'
}
