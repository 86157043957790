import axios from 'axios'
import { API_URL } from '@/const'

export const pathAxiosShowMsg = async (channel, id) => {
  try {
    const { data } = await axios.patch(`${API_URL.MESSAGES}/${channel}/${id}`)
    return data
  } catch (e) {
    console.log(e.response.data)

    return {}
  }
}
