<template>
  <div
    class="adm-msg-green"
    v-if="isDeleted"
    title="показать сообщение"
    @click.stop="onClickBtn(id, false)"
  >
    ✔️
  </div>
  <div
    class="adm-msg adm-msg-red"
    v-else
    title="скрыть сообщение"
    @click.stop="onClickBtn(id, true)"
  >
    ✖️
  </div>
</template>

<script>
export default {
  name: 'admin-msg-panel',
  components: {},
  props: {
    id: { type: String },
    isDeleted: { type: Boolean }
  },
  methods: {
    onClickBtn(id, hide) {
      this.$root.$emit('on-root-del-msg', id, hide)
    }
  }
}
</script>

<style lang="sass">
@import './styles/index.scss'
</style>
