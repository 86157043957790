<template>
  <div class="editor-reply">
    <div class="ed-re-msg">
      <div class="msg-reply-fio">{{ msg.is_anon ? 'Анонимный пользователь' : msg.fullname }}</div>
      <div>{{ msg.text }}</div>
    </div>
    <div class="reply-msg-close" @click="onClick">
      <Close />
    </div>
  </div>
</template>
<script>
import Close from '@/pic/svg/close.vue'
export default {
  name: 'chat-editor-reply',
  components: { Close },
  props: {
    msg: { type: Object }
  },
  data() {
    return {
      fullname: ''
    }
  },
  methods: {
    onClick() {
      this.$root.$emit('on-root-reply-msg', '')
    }
  }
}
</script>

<style lang="sass">
@import './styles/index.scss'
</style>
