import axios from 'axios'
import { API_URL } from '@/const'

export const delAxiosMsg = async (channel, id) => {
  try {
    const { data } = await axios.delete(`${API_URL.MESSAGES}/${channel}/${id}`)
    return data
  } catch (e) {
    console.log(e.response.data)

    return {}
  }
}
