<template>
  <div class="reply-btn" title="ответить" @click.stop="onClickBtn">←</div>
</template>

<script>
export default {
  name: 'reply-btn',
  components: {},
  props: {
    id: { type: String }
  },
  methods: {
    onClickBtn() {
      this.$root.$emit('on-root-reply-msg', this.id)
    }
  }
}
</script>

<style lang="sass">
@import './styles/index.scss'
</style>
